import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.css';
import ResizeObserver from 'resize-observer-polyfill';

window.ResizeObserver = ResizeObserver;

/**
 * Initialize scrollbar.
 *
 * @param {jQuery} $scrollbars
 */
export const initScrollbar = ( $scrollbars ) => {
	let timeout = 2000;

	const $body = $( 'body' );

	const bodyHasAdmin = $body.hasClass( 'wp-admin' );
	const bodyHasIframe = $body.hasClass( 'block-editor-iframe__body' );

	if ( bodyHasAdmin || bodyHasIframe ) {
		timeout = 5000;
	}

	setTimeout( () => {
		$scrollbars.each( ( _, scrollbar ) => {
			const $scrollbar = $( scrollbar );

			if ( $scrollbar.attr( 'data-simplebar' ) === 'init' ) {
				return;
			}

			const trackClasses = [ 'simplebar-track' ];

			if ( $scrollbar.attr( 'data-scrollbar-theme' ) === 'dark' ) {
				trackClasses.push( 'simplebar-track--dark' );
			}

			new SimpleBar( scrollbar, {
				classNames: {
					track: trackClasses.join( ' ' ),
				},
			} );
		} );
	}, timeout );
};
