import { initScrollbar } from '../modules/scrollbar';
import { isDeviceWidthBelow } from '../utils/device';
import { throttle } from '../utils/rate-limiter';

const initBlock = ( $block ) => {
	const $services = $block.find( '.js-service' );

	const ANIMATION_DURATION = 400;
	const RESPONSIVE_BREAKPOINT = 1023;

	/**
	 * Initialize scrollbar.
	 */
	initScrollbar( $block.find( '.js-scrollbar' ) );

	/**
	 * Prepare service elements visibility based on device width and active state.
	 * Shows/hides elements for responsive and desktop views.
	 *
	 * @param {jQuery} $service - The service jQuery element to prepare
	 */
	const prepareServiceElements = ( $service ) => {
		const $elementToShow = $service.find( '.js-service-element-to-show' );
		const $elementToShowResponsive = $service.find( '.js-service-element-to-show-responsive' );

		$elementToShow.show();
		$elementToShowResponsive.show();

		if ( !isDeviceWidthBelow( RESPONSIVE_BREAKPOINT ) ) {
			$elementToShowResponsive.hide();
		}

		if ( $service.hasClass( 'is-active' ) ) {
			return;
		}

		$elementToShow.hide();
		$elementToShowResponsive.hide();
	};

	/**
	 * Prepare the elements to show.
	 */
	$services.each( ( _, service ) => {
		const $service = $( service );

		prepareServiceElements( $service );

		$( window ).on( 'resize', () => {
			prepareServiceElements( $service );
		} );
	} );

	/**
	 * Hides a service by removing active class and animating out elements
	 *
	 * @param {jQuery} $service - The service jQuery element to hide
	 */
	const hideService = ( $service ) => {
		const $elementsToShow = $service.find( '.js-service-element-to-show' );
		const $elementsToShowResponsive = $service.find( '.js-service-element-to-show-responsive' );

		if ( isDeviceWidthBelow( RESPONSIVE_BREAKPOINT ) ) {
			$elementsToShow.slideToggle();
			$elementsToShowResponsive.slideToggle();
		} else {
			$elementsToShow.fadeToggle();
		}

		setTimeout( () => {
			$service.removeClass( 'is-active' );
		}, ANIMATION_DURATION );
	};

	/**
	 * Shows a service by adding active class and animating in elements
	 *
	 * @param {jQuery} $service - The service jQuery element to show
	 */
	const showService = ( $service ) => {
		const $elementsToShow = $service.find( '.js-service-element-to-show' );
		const $elementsToShowResponsive = $service.find( '.js-service-element-to-show-responsive' );

		$service.addClass( 'is-active' );

		if ( !isDeviceWidthBelow( RESPONSIVE_BREAKPOINT ) ) {
			setTimeout( () => {
				$elementsToShow.fadeToggle();
			}, ANIMATION_DURATION );
		} else {
			$elementsToShow.slideToggle();
			$elementsToShowResponsive.slideToggle();
		}
	};

	/**
	 * Handle service click to toggle visibility of elements and active state
	 */
	$services.on( 'click', throttle( function( event ) {
		const isAnchorClicked = $( event.target ).is( 'a' );

		if ( isAnchorClicked ) {
			return;
		}

		event.preventDefault();

		const $service = $( this );

		// Close all other active services first
		$services.filter( '.is-active' ).not( $service ).each( ( _, otherService ) => {
			hideService( $( otherService ) );
		} );

		$service.hasClass( 'is-active' ) ? hideService( $service ) : showService( $service );
	}, 600 ) );
};

app.loadBlock( initBlock, 'our-services', '.js-section-our-services' );
